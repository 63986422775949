import React from 'react';
import {Image} from './styles'


function Clients() {
  return (

    <div id="clients" class="section-padding">
      <div class="container">
        <div class="section-header text-center">
          < div class="wow fadeInDown" data-wow-delay="0.1s"></div>
          <h2 class="section-title "><br/>Clientes</h2>
        </div>
        
        <div class="row text-align-">
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
             
              <Image
                  src={ require("../../assets/img/clients/img5.webp").default}
                  alt="Ecosistemas Computação - Peçai"
                  height={250}
                  width={250}
                  title="Ecosistemas Computação - Peçai"
                />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
               
                src={ require("../../assets/img/clients/img6.webp").default}
                alt="Ecosistemas Computação - Absoluta"
                height={250}
                width={250}
                title="Ecosistemas Computação - Absoluta"
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
              
                src={ require("../../assets/img/clients/img7.webp").default}
                alt="Ecosistemas Computação - Loja Magnata"
                height={250}
                width={250}
                title="Ecosistemas Computação - Loja Magnata"
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
               
                
                src={ require("../../assets/img/clients/img8.webp").default}
                alt="Ecosistemas Computação - Meu amigo Pet"
                title="Ecosistemas Computação - Meu amigo Pet"
                height={250}
                width={250}
                // layout= "fill"
              />
            </div>
          </div>
        </div>





       <div class="row text-align-">
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">

            <Image
                  class="img-fluid"
                  src={ require("../../assets/img/clients/img14.webp").default}
                  alt="Ecosistemas Computação - Loja Marissol"
                  title="Ecosistemas Computação - Loja Marissol"
                  height={250}
                width={250}
                  // layout= "fill"
                />
              
            </div>
              
             
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">

            <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img4.webp").default}
                alt="Ecosistemas Computação - Cinderela Móveis"
                title="Ecosistemas Computação - Cinderela Móveis"
                height={250}
                width={250}
                // layout= "fill"
              />
              
             
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">

            <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img3.webp").default}
                alt="Ecosistemas Computação - Loja Desafio"
                title="Ecosistemas Computação - Loja Desafio"
                height={250}
                width={250}
                // layout= "fill"
              />
              
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">

            <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img12.webp").default}
                alt="Ecosistemas Computação - Mercado Toniolli"
                title="Ecosistemas Computação - Mercado Toniolli"
                height={250}
                width={250}
                // layout= "fill"
              />
              
            </div>
          </div>
        </div>


        <div class="row text-align-">
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
                  class="img-fluid"
                  src={ require("../../assets/img/clients/img9.webp").default}
                  alt="Ecosistemas Computação - Pax Universal"
                  title="Ecosistemas Computação - Pax Universal"
                  height={250}
                width={250}
                  // layout= "fill"
                />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img10.webp").default}
                alt="Ecosistemas Computação - Restaurante Vó Mariazinha"
                title="Ecosistemas Computação - Restaurante Vó Mariazinha"
                height={250}
                width={250}
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img11.webp").default}
                alt="Ecosistemas Computação - Imóveis Palhano"
                title="Ecosistemas Computação - Imóveis Palhano"
                height={250}
                width={250}
                
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
            <Image
                class="img-fluid"
                
                src={ require("../../assets/img/clients/img1.webp").default}
                alt="Ecosistemas Computação - Cimencal Construções"
                title="Ecosistemas Computação - Cimencal Construções"
                height={250}
                width={250}
                // layout= "fill"
              />
            </div>
          </div>
        </div>





        <div class="row text-align-">
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
            <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img2.webp").default}
                alt="Ecosistemas Computação - Sepaja"
                title="Ecosistemas Computação - Sepaja"
                height={250}
                width={250}
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img13.webp").default}
                alt="Ecosistemas Computação - Supote Pecuaria"
                title="Ecosistemas Computação - Supote Pecuaria"
                height={250}
                width={250}
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            <div class="client-item-wrapper">
              <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img15.webp").default}
                alt="Ecosistemas Computação - Recupera Assessoria"
                title="Ecosistemas Computação - Recupera Assessoria"
                height={250}
                width={250}
                
                // layout= "fill"
              />
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-xs-12 wow fadeInUp" data-wow-delay="0.1s">
            {/* <div class="client-item-wrapper">
              <Image
                class="img-fluid"
                src={ require("../../assets/img/clients/img12.webp").default}
                alt="Ecosistemas Computação - Mercado Toniolli"
                title="Ecosistemas Computação - Mercado Toniolli"
                height={250}
                width={250}
                // layout= "fill"
              /></div>*/}
           
          </div>
        </div>




      </div>
     
    </div>
  )
}

export default Clients;