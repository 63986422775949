import styled from 'styled-components';

export const Image = styled.img`
  
  height: 50px;

  padding-bottom: 10px;


`;


